import { lazy, Suspense, useEffect, useState } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { SnackbarProvider } from 'notistack'

import { useAuth } from './context/authContext'
import './App.css'
import Layout from './contaniers/Layout/Layout'
import PageLoader from './components/Loaders/PageLoader'
import PendingOrderListingPage from './pages/PendingOrder/PendingOrderListing'
import DraftPOView from './pages/DraftPOView'
import PurchaseOrders from './pages/Purchase Orders'
import ReOrderPoints from './pages/ReOrderPoints'
import ReOrderPointsSummary from './pages/ReOrderPointsSummary'
import QCListing from './pages/QCListing'
import QCDetails from './pages/QCDetails'

const ListingPage = lazy(() => import('./pages/ListingPage/ListingPage'))
const Login = lazy(() => import('./pages/Login/Login'))
const Sidebar = lazy(() => import('./components/Sidebar/Sidebar'))
const ProfilePage = lazy(() => import('./pages/ProfilePage/ProfilePage'))
const Manage = lazy(() => import('./pages/Manage/Manage'))
const CreateDoctorCart = lazy(() =>
  import('./pages/CreateDoctorCart/CreateDoctorCart'),
)
const Cart = lazy(() => import('./pages/Cart/Cart'))
const OrderPage = lazy(() => import('./pages/OrderPage/OrderPage'))
const ProcuremetItemListingPage = lazy(() =>
  import('./pages/Procurement/ProcurementListing'),
)

function App() {
  const { login, setLogin, setProfile, setPermissions, permissions } = useAuth()

  useEffect(() => {
    const auth = localStorage.getItem('platinumRxDashboardToken')
    if (!auth) setLogin(false)
    let detail = JSON.parse(
      localStorage.getItem('platinumRxDashboardUserDetail'),
    )
    if (detail) {
      setProfile(detail)
      let roles = []
      detail.roles.map((role) => {
        roles = [...roles, ...role.permissions]
        roles.push(role.name)
      })
      setPermissions(roles)
    }
  }, [])

  return (
    <SnackbarProvider autoHideDuration={2000} maxSnack={3}>
      <div className="App">
        <BrowserRouter>
          <Suspense fallback={<PageLoader />}>
            {!login ? (
              <Login />
            ) : (
              <>
                <Sidebar />
                <Routes>
                  {permissions.includes('QC_STATUS_UPDATE') ? (
                    <Route
                      path="/"
                      element={
                        <Layout title="Summary">
                          <QCListing />
                        </Layout>
                      }
                    />
                  ) : (
                    <Route
                      path="/"
                      element={
                        <Layout title="Summary">
                          <ListingPage />
                        </Layout>
                      }
                    />
                  )}

                  <Route
                    path="/qc-order/:orderId/:statusId"
                    element={
                      <Layout title="">
                        <QCDetails />
                      </Layout>
                    }
                  />
                  <Route
                    path="/procurement-dashboard/items"
                    element={
                      <Layout title="Summary">
                        <ProcuremetItemListingPage />
                      </Layout>
                    }
                  />
                  <Route
                    path="/procurement-dashboard/DraftPOView"
                    element={
                      <Layout title="Summary">
                        <DraftPOView />
                      </Layout>
                    }
                  />
                  <Route
                    path="/procurement-dashboard/pending-orders"
                    element={
                      <Layout title="Summary">
                        <PendingOrderListingPage />
                      </Layout>
                    }
                  />
                  <Route
                    path="/procurement-dashboard/purchase-orders"
                    element={
                      <Layout title="Summary">
                        <PurchaseOrders />
                      </Layout>
                    }
                  />
                  <Route
                    path="/procurement-dashboard/reorder-points"
                    element={
                      <Layout title="Summary">
                        <ReOrderPoints />
                      </Layout>
                    }
                  />
                  <Route
                    path="/procurement-dashboard/reorder-points-summary"
                    element={
                      <Layout title="Summary">
                        <ReOrderPointsSummary />
                      </Layout>
                    }
                  />
                  <Route
                    path="/order/:orderId/:statusId"
                    element={
                      <Layout title="">
                        <OrderPage />
                      </Layout>
                    }
                  />
                  <Route
                    path="/qc-listing"
                    element={
                      <Layout title="QCListing">
                        <QCListing />
                      </Layout>
                    }
                  />
                  <Route
                    path="/manage"
                    element={
                      <Layout title="Manage">
                        <Manage />
                      </Layout>
                    }
                  />
                  <Route
                    path="/profile"
                    element={
                      <Layout title="Profile">
                        <ProfilePage />
                      </Layout>
                    }
                  />
                  <Route
                    path="/create-cart/:userId/:orderId/:newCartId"
                    element={
                      <Layout title="Add in cart">
                        <CreateDoctorCart />
                      </Layout>
                    }
                  />
                  <Route
                    path="/cart/:orderId/:newCartId"
                    element={
                      <Layout title="Cart">
                        <Cart />
                      </Layout>
                    }
                  />
                </Routes>{' '}
              </>
            )}
          </Suspense>
        </BrowserRouter>
      </div>
    </SnackbarProvider>
  )
}

export default App
