import createAxios from './createAxios'

const browserAxios = createAxios()

export const InternalUserLogin = (axios = browserAxios) => (data) =>
  axios.post({ url: '/internal-users/login', data })

export const sendOtp = (axios = browserAxios) => (data) =>
  axios.post({ url: '/auth/sendOtp', data })

export const getPharmacistOrders = (axios = browserAxios) => (data) =>
  axios.get({
    url: `/orders/pharmacistOrders`,
    params: data,
  })

export const getDoctorOrders = (axios = browserAxios) => (data) =>
  axios.get({
    url: `/orders/doctorOrders`,
    params: data,
  })

export const getAllOrders = (axios = browserAxios) => (data) =>
  axios.get({
    url: `/orders/internal/fetchOrders`,
    params: data,
  })

export const getAllPharmacists = (axios = browserAxios) => () =>
  axios.get({ url: '/internal-users/getAllPharmacists' })

export const getAllDoctors = (axios = browserAxios) => () =>
  axios.get({ url: '/internal-users/getAllDoctors' })

export const getPastPrescriptions = (axios = browserAxios) => (data) =>
  axios.get({ url: '/digitizedPrescriptions/pastPrescriptions', params: data })

export const assignPharmacist = (axios = browserAxios) => (data) =>
  axios.post({ url: '/orders/assignPharmacist', data })

export const assignDoctor = (axios = browserAxios) => (data) =>
  axios.post({ url: '/orders/assignDoctor', data })

export const markPharmacistUnavailable = (axios = browserAxios) => (data) =>
  axios.post({
    url: `/internal-users/changePharmacistAvailability/${data.pharmacistId}`,
    data: { active: data.active },
  })

export const markDoctorUnavailable = (axios = browserAxios) => (data) =>
  axios.post({
    url: `/internal-users/changeDoctorAvailability/${data.doctorId}`,
    data: { active: data.active },
  })

export const createDigitizedPrx = (axios = browserAxios) => (data) =>
  axios.post({ url: '/digitizedPrescriptions/bulkCreate', data })

export const deleteDigitizedPrx = (axios = browserAxios) => () =>
  axios.delete({ url: '/digitizedPrescriptions/2' })

export const getOrderDetails = (axios = browserAxios) => (data) =>
  axios.get({ url: `/orders/internal/fetchOrderDetails/${data.orderId}` })

export const getUserPrescriptions = (axios = browserAxios) => (data) =>
  axios.get({ url: `/files/prescriptions`, params: { cartId: data?.cartId } })

export const cloneCart = (axios = browserAxios) => (data) =>
  axios.post({ url: `/orders/internal/cloneOrderCart/${data.orderId}` })

export const getDoctorInfo = (axios = browserAxios) => (data) =>
  axios.get({ url: '/ops/internal/doctor', params: data })

export const getCartDetail = (axios = browserAxios) => (data) =>
  axios.get({ url: `/cart/internal/fetchCartFromId/${data.cartId}` })

export const addItemInCartByQuantity = (axios = browserAxios) => (data) =>
  axios.post({
    url: `/cart/internal/${data.userId}/setItemQuantity?cartGeneratedBy=doctor&cartId=${data.cartId}`,
    data,
  })

export const updateOrderStatus = (axios = browserAxios) => (data) =>
  axios.post({ url: '/orders/updateDoctorReviewStatus', data })

export const signAndSend = (axios = browserAxios) => (data) =>
  axios.post({ url: '/ops/internal/createDoctorOrOpsCart', data })

export const createPrxForDoctorCart = (axios = browserAxios) => (data) =>
  axios.post({ url: '/ops/internal/generatePrescriptionForCart', data })

export const placeOrderWithNewCart = (axios = browserAxios) => (data) =>
  axios.post({
    url: `/payment/internal/cloneOrderWithNewCart/${data.orderId}`,
    data,
  })

export const makeCall = (axios = browserAxios) => (data) =>
  axios.post({ url: `orders/callPatient/${data.id}` })

export const fetchUserDetails = (axios = browserAxios) => (id) =>
  axios.get({
    url: `/users/fetchuserdetails`,
    headers: { user_id: id },
  })

export const addUpdateUserPatient = (axios = browserAxios) => (data) =>
  axios.post({ url: `/users/internal/addUpdateUserPatient`, data })

export const getProcurementItems = (axios = browserAxios) => (data) =>
  axios.post({
    url: `/procurement/items`,
    data: data?.data,
    params: data?.params,
  })

export const createPurchaseOrder = (axios = browserAxios) => (data) =>
  axios.post({
    url: `/procurement/createPurchaseOrder`,
    data: data,
  })

export const createDraftPurchaseOrder = (axios = browserAxios) => (data) =>
  axios.put({
    url: `/procurement/draftPurchaseOrderItem`,
    data: data,
  })

export const getPendingOrdersOfDrug = (axios = browserAxios) => (
  masterDrugCode,
) =>
  axios.get({
    url: `/procurement/items/${masterDrugCode}/orders`,
  })

export const getWarehousePendingOrders = (axios = browserAxios) => (data) =>
  axios.post({
    url: `/procurement/warehousePendingOrders`,
    params: data.params,
    data: data.data,
  })

export const getPendingOrderItems = (axios = browserAxios) => (orderId) =>
  axios.get({
    url: `/procurement/orderItemProcurement/${orderId}`,
  })

export const draftPurchaseOrderItem = (axios = browserAxios) => (data) =>
  axios.post({
    url: `/procurement/draftPurchaseOrderItems`,
    params: data.params,
    data: data.data,
  })

export const deleteDraftPurchaseOrderItem = (axios = browserAxios) => (id) =>
  axios.delete({
    url: `/procurement/draftPurchaseOrderItem/${id}`,
  })

export const getPurchaseOrderItems = (axios = browserAxios) => (data) =>
  axios.post({
    url: `/procurement/purchaseOrderItems`,
    params: data.params,
    data: data.data,
  })

export const createPurchaseOrderItems = (axios = browserAxios) => (data) =>
  axios.post({
    url: `/procurement/createPurchaseOrder`,
    params: data,
  })

export const updatePurchaseOrderStatus = (axios = browserAxios) => (data) =>
  axios.put({
    url: `/procurement/purchaseOrderItem/${data.id}`,
    data: data.data,
  })

export const getDrugReorderPoints = (axios = browserAxios) => (data) =>
  axios.post({
    url: `/procurement/drugReorderPoints`,
    params: data.params,
    data: data.data,
  })

export const putDrugReorderPoint = (axios = browserAxios) => (data) =>
  axios.put({
    url: `/procurement/drugReorderPoint`,
    data: data,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })

export const getReorderSuggestions = (axios = browserAxios) => (data) =>
  axios.post({
    url: `/procurement/reorderSuggestions`,
    params: data.params,
    data: data.data,
  })

export const MarkProcurementOrderAsQCDone = (axios = browserAxios) => (data) =>
  axios.post({
    url: `/ops/markOrderAsQcDone`,
    data: data,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
