import { useEffect, useState } from 'react'
import { getAllOrders } from '../../utils/api'
import './QCListing.module.scss'
import LeadRow from '../../contaniers/QCListing/LeadRow'
import ComponentLoader from '../../components/Loaders/ComponentLoader'
import SelectStatus from '../../contaniers/QCListing/SelectQCStatus'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import dayjs from 'dayjs'
import { useMediaQuery } from '@mui/material'
import { useTheme, TextField } from '@mui/material'
import styles from './QCListing.module.scss' // Updated import for SCSS

function QCListing() {
  const [statusId, setStatusId] = useState(60)
  const [orderIds, setOrderIds] = useState('')
  const [orders, setOrders] = useState([])
  const [skip, setSkip] = useState(0)
  const [hasmore, setHasmore] = useState(true)
  const [loading, setLoading] = useState(false)
  const [roleChanged, setRoleChanged] = useState(false)
  const limit = 20
  const [openDropdown, setOpenDropdown] = useState(false)
  const [fromDate, setFromDate] = useState(null)
  const [toDate, setToDate] = useState(null)
  const theame = useTheme()
  const isMobile = useMediaQuery(theame.breakpoints.down('sm'))

  const handleOrderIdChange = (e) => {
    setOrderIds(e.target.value)
    setSkip(0)
    setOrders([])
    setHasmore(true)
  }

  const fetchData = async () => {
    let TODate = null
    let FROMDate = null
    if (toDate) {
      TODate = convertDateToEpoch(toDate, true)
      FROMDate = convertDateToEpoch(fromDate, true)
    }
    if (!hasmore) return
    setLoading(true)
    try {
      const data = {
        count: limit,
        skip: skip,
        statusIds: statusId,
        from: FROMDate,
        to: TODate,
        orderIds: orderIds,
      }
      let response = await getAllOrders()(data)
      if (response) {
        console.log(response)
        setOrders((prev) => {
          return [...prev, ...response.data.data.orders]
        })
        setSkip((prev) => prev + limit)
        setHasmore(response.data.data.orders.length)
        if (response.data.data.orders.length === 0) setHasmore(false)
      }
      setRoleChanged(false)
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const toggleWithSuccess = () => {
    let detail = JSON.parse(
      localStorage.getItem('platinumRxDashboardUserDetail'),
    )
    if (detail) {
      let roleList = []
      detail.roles.map((role) => {
        roleList = [...roleList, ...role.permissions]
        roleList.push(role.name)
      })
      setSkip(0)
      setOrders([])
      setHasmore(true)
      setRoleChanged(true)
    }
  }

  useEffect(() => {
    fetchData()
  }, [roleChanged, statusId, toDate, orderIds])

  const handleFromDateChange = (newValue) => {
    setFromDate(newValue)
    if (newValue && toDate && dayjs(newValue).isAfter(toDate)) {
      setToDate(null)
    }
  }

  const handleToDateChange = (newValue) => {
    setToDate(newValue)
    setSkip(0)
    setOrders([])
    setOrderIds('')
    setHasmore(true)
  }

  function convertDateToEpoch(date, inSeconds = false) {
    if (!date) return null

    const epochMilliseconds = date.valueOf()

    if (inSeconds) {
      return Math.floor(epochMilliseconds / 1000)
    }

    return epochMilliseconds
  }

  return (
    <div className={styles.home}>
      <div className={styles.filterHeader}>
        <SelectStatus
          statusId={statusId}
          setStatusId={setStatusId}
          openDropdown={openDropdown}
          setOpenDropdown={setOpenDropdown}
          setHasmore={setHasmore}
          setSkip={setSkip}
          setOrders={setOrders}
        />
       <TextField
          fullWidth
          variant="outlined"
          name="masterDrugCode"
          onChange={(e) => handleOrderIdChange(e)}
          label="Enter Order Ids (Comma Separated)"
        />
        <div className={styles.dates}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['DatePicker']}>
              <DatePicker
                className={styles.datePicker}
                label="From Date"
                value={fromDate}
                onChange={handleFromDateChange}
              />
            </DemoContainer>
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['DatePicker']}>
              <DatePicker
                className={styles.datePicker}
                label="To"
                value={toDate}
                onChange={handleToDateChange}
                minDate={fromDate}
                disabled={!fromDate}
              />
            </DemoContainer>
          </LocalizationProvider>
        </div>
      </div>
      <table className={styles.listingPageTable}>
        <thead className={styles.listingPageTableHead}>
          <tr>
            {!isMobile && <th className={styles.border1}>S.No</th>}
            <th>Order ID</th>
            <th>Order Creation Date</th>
            <th>Customer Id</th>
            {!isMobile && <th>Order Fulfillment Date</th>}
          </tr>
        </thead>
        <tbody className={styles.listingPageTableBody}>
          {orders?.map((item, index) => (
            <LeadRow
              loading={loading}
              hasmore={hasmore}
              fetchData={fetchData}
              index={index}
              item={item}
              orders={orders}
              toggleWithSuccess={toggleWithSuccess}
            />
          ))}
        </tbody>
      </table>
      {loading && <ComponentLoader />}
    </div>
  )
}

export default QCListing
