'use client'
import React from 'react'
import { Divider } from '@mui/material'
import './bill-details.css'

const BillingDetails = ({ data = {}, isMobile, paymentMode }) => {
  const {
    shippingCharges = 0,
    originalShippingCharges = 0,
    totalAmountToBePaid = 0,
  } = data

  const greenStyle = { color: '#219653' }

  const billDetails = [
    {
      title: 'Total Amount',
      value: 'totalMrp',
    },
    {
      title: 'Discount',
      value: 'totalDiscount',
      valueStyle: greenStyle,
      keyStyle: greenStyle,
    },
    {
      title: 'Coupon Discount',
      value: 'couponDiscount',
      valueStyle: greenStyle,
      keyStyle: greenStyle,
    },
    {
      title: 'Prx Coin',
      value: 'prxCoin',
      valueStyle: greenStyle,
      keyStyle: greenStyle,
    },
    {
      title: 'Prx Cash',
      value: 'prxCash',
      valueStyle: greenStyle,
      keyStyle: greenStyle,
    },
    {
      title: 'Packaging Charges',
      value: 'packagingAndHandlingCharges',
      style: {
        display: data['packagingAndHandlingCharges'] == 0 ? 'none' : 'flex',
      },
    },
    {
      title: 'Shipping Charges',
      value: 'shippingCharges',
    },
  ]
  return (
    <>
      {isMobile && <p className={'title'}>Bill Details</p>}
      <div className={'bill_details_container'}>
        {billDetails.map((charges, index) => (
          <div key={index}>
            {' '}
            {(charges.value !== 'shippingCharges' && !data[charges.value]) ||
            (charges.value === 'shippingCharges' &&
              (data[charges.value] === undefined ||
                data[charges.value] === null)) ? null : (
              <div className={'bill_item'} style={charges.style}>
                <p
                  className={'bill_item__title'}
                  style={charges.keyStyle || {}}
                >
                  {charges?.title}
                </p>
                <div className={'bill_middle_line'}></div>
                {charges?.value === 'shippingCharges' &&
                shippingCharges === 0 ? (
                  <div className={'bill_item__value_container'}>
                    {originalShippingCharges > 0 && (
                      <p
                        className={`bill_item__value_container__value original_shipping_charges`}
                      >
                        ₹ {originalShippingCharges}
                      </p>
                    )}
                    <p className={'bill_item__value_container__free'}>FREE</p>
                  </div>
                ) : charges?.value === 'shippingCharges' &&
                  shippingCharges > 0 ? (
                  <p
                    className={'bill_item__value_container__value'}
                    style={charges.valueStyle || {}}
                  >
                    ₹ {shippingCharges}
                    {originalShippingCharges > shippingCharges && (
                      <span
                        className={'bill_item__value_container__value__span'}
                      >
                        ₹ {originalShippingCharges}
                      </span>
                    )}
                  </p>
                ) : (
                  <p
                    className={'bill_item__value_container__value'}
                    style={charges.valueStyle || {}}
                  >
                    ₹ {data[charges.value] || 0}
                  </p>
                )}
              </div>
            )}
          </div>
        ))}
        <Divider />
        <div className={'total_pay'}>
          <p className={'total_pay__title'}>
            Total Paid:{' '}
            <span style={{ color: '#6d7478', fontSize: '14px' }}>
              {paymentMode || 'N/A'}
            </span>
          </p>
          <p className={'total_pay__value'}>₹ {totalAmountToBePaid || 0}</p>
        </div>
      </div>
    </>
  )
}

export default BillingDetails
